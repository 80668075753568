@media (min-width: 320px) and (max-width: 600px) {
	.navbar-brand {
		margin-right: 0;
	}
	.navbar-expand-lg > .container {
		width: 100%;
	}

	body {
		max-width: 100%;
		p {
			width: initial !important;
			// width: 20px;
		}
	}

	.big-title {
		font-size: 44px;
	}
	/*PARA EL INDEX---------------------------------------------------------*/
	.section-1 {
		.jumbotron {
			height: auto;
			padding: 0;
			margin-top: 20rem;
		}
	}

	.main-title {
		font-size: 30px;
	}

	.section-2 {
		height: auto;
		background: $main-blue !important;
		.caption-mov {
			p{
				color: $white;
			}
			padding: 1rem;
		}
	}
	.section-3 {
		height: auto;
		padding: 2rem 0;
		background-size: 100% !important;
		.parallax-overlay{
			background: transparent !important;
		}
		.caption-mov {
			background: rgba(0, 0, 0, 0.8);
			padding: 1rem;
		}
	}
	.section-7 {
		p.preety-font {
			margin-left: 0;
		}
	}

	body {
		.container {
			width: initial;

			p {
				width: initial;
			}
		}
	}
	.row.socials {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin: 2rem 0 0;
		max-width: 100%;
		justify-content: center;
		text-align: center;
		.col-lg-2{
			width: auto !important;
			padding: 0 5px;
		}
	  }
	  footer{
		text-align: center;
		p{
			margin-bottom: 1rem;
			text-align: center;
		}
	  }

	/*************************************************************************************/
}
