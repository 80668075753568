html {
	scroll-behavior: smooth;
}

@font-face {
	font-family: "Telefonica Sans";
	src: url("./fonts/Telefonica-Sans-Thin.otf");
	font-weight: 100;
}
@font-face {
	font-family: "Telefonica Sans";
	src: url("./fonts/Telefonica-Sans-Regular.otf");
	font-weight: normal;
}
@font-face {
	font-family: "Telefonica Sans";
	src: url("./fonts/Telefonica-Sans-Medium.otf");
	font-weight: 500;
}

body {
	background-color: $white;
	font-family: "Telefonica Sans" !important;

	p {
		font-family: "Telefonica Sans";
		font-size: 16px;
		margin-bottom: 0;
	}

	.container {
		width: 1024px;
	}
}

.ml-6 {
	margin-left: 2.8rem;
}

.navbar {
	background: $white;
	min-height: 62px;

	.container {
		align-items: center;
		display: flex;

		img {
			width: 123px;
		}
	}
}

.caps {
	text-transform: uppercase;
}

.sub-title {
	font-family: "Telefonica Sans";
	font-size: 22px;
	font-weight: 100;
}

.main-title {
	font-family: "Telefonica Sans";
	font-size: 36px;
	font-weight: 100;
	letter-spacing: 0;
	line-height: 45px;
}

.main-sub-title {
	font-family: "Telefonica Sans";
	font-size: 28px;
	font-weight: 100;
	letter-spacing: 0;
	line-height: 45px;
}
/*SECTION 1*/
.section-1 {
	margin: 0;
	min-height: 666px;
	.jumbotron {
		align-items: left;
		background: none;
		display: flex;
		flex-direction: column;
		height: 666px;
		justify-content: center;
		margin: 0;
		padding: 0 0 0 230px;

		p {
			color: $white;
			font-size: 30px;
			&.hashtag {
				font-size: 25px;
			}
			&.preety-font {
				font-size: 25px;
				margin-top: 4rem;
				span {
					font-family: "Playfair Display", serif;
					font-style: italic;
				}
			}
		}
		h5 {
			color: $white;
			font-size: 40px;
			font-weight: 700;
			line-height: 0.5;
		}
		a {
			margin-top: 3rem;
		}
	}
}

.btn.outer-white {
	color: $white;
	border: 1px solid $white;
	background: transparent;
	width: fit-content;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 0;
	padding: 0.5rem 3rem;
	&:hover {
		border-color: $main-blue;
		color: $main-blue;
		background: $white;
	}
}
.btn.outer-blue {
	color: $main-blue;
	border: 1px solid $main-blue;
	background: transparent;
	width: fit-content;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 0;
	padding: 0.5rem 3rem;
	&:hover {
		border-color: $white;
		color: $white;
		background: $main-blue;
	}
}

/*SECTION 2*/
.section-2 {
	padding: 8rem 0;
	background: rgb(1, 157, 244);
	background: linear-gradient(-270deg, rgba(1, 157, 244, 1) 50%, rgba(255, 255, 255, 1) 50%);
	height: 536px;
	.container {
		height: 100%;
		.row {
			height: 100%;
			align-items: center;
		}
	}

	h1 {
		color: $white;
		font-size: 40px;
		font-weight: 100;
		span {
			font-family: "Playfair Display", serif;
			font-style: italic;
		}
	}
	p {
		color: $main-blue;
		font-size: 22px;
	}
}

/*SECTION 3*/
.section-3 {
	padding: 8rem 0;
	position: relative;
	&.parallax {
		background: url("./img/index/parallax.jpg");
		background-attachment: fixed;
		background-position: left;
		background-repeat: no-repeat;
		background-size: 60%;
		.parallax-overlay {
			background: rgb(1, 157, 244);
			background: linear-gradient(270deg, rgba(1, 157, 244, 1) 45%, rgba(255, 255, 255, 0) 45%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.container {
		height: 100%;
		.row {
			height: 100%;
			align-items: center;
		}
	}

	h1 {
		color: $white;
		font-size: 35px;
		font-weight: 100;
		span {
			font-family: "Playfair Display", serif;
			font-style: italic;
		}
	}
	p {
		color: $white;
		font-size: 19px;
	}
	a {
		margin-top: 3rem;
	}
}

/*SECTION 4*/
.section-4 {
	padding: 5rem 0;
	text-align: center;
	h2 {
		color: $main-blue;
		font-size: 35px;
		font-weight: 200;
	}
	h3 {
		color: $main-blue;
		font-size: 25px;
		font-weight: 100;
	}
	.the-icons {
		height: 66px;
		margin-bottom: 1rem;
	}
}

/*SECTION 5*/
.section-5 {
	padding: 5rem 0;
	.section-title {
		color: $main-blue;
		margin: 0 auto 3rem;
		font-size: 45px;
		font-weight: 400;
		text-align: center;
	}
	.card {
		border: 0;
		border-radius: 0;
		.caption-card {
			background: $main-blue;
			padding: 1rem;
			text-align: center;
			p {
				color: $white;
				font-size: 30px;
				text-align: center;
				font-weight: 100;
			}
			.right-arrow {
				margin-left: auto;
				width: 35px;
				display: block;
				cursor: pointer;
			}
		}
	}
}

/*SECTION 6*/
.section-6 {
	padding: 4rem 0;
	position: relative;
	h1 {
		font-size: 45px;
		color: $main-blue;
	}
	p {
		font-size: 19px;
		color: $main-blue;
	}
	form {
		input {
			border: 1px solid $main-blue;
			background: $sec-blue;
			font-size: 15px;
			color: $main-blue;
			padding: 1rem;
			margin-bottom: 2rem;
			width: 100%;
			&::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $main-blue;
				opacity: 1; /* Firefox */
			}

			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: $main-blue;
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: $main-blue;
			}
		}
	}
}

/*SECTION 7*/
.section-7 {
	padding: 1rem 0;
	background: $main-blue;
	p {
		color: $white;
		font-size: 22px;
		strong {
			font-size: 30px;
			font-weight: 800;
		}
		&.preety-font {
			font-size: 25px;
			margin-left: auto;
			width: fit-content;
			span {
				font-family: "Playfair Display", serif;
				font-style: italic;
			}
		}
	}
}

.dif-font {
	font-family: "Telefonica Sans";
}

.section-privacy {
	margin: 6rem 0;
	h1 {
		font-size: 30px;
		font-weight: 800;
		color: $dark-blue;
	}
	p {
		font-size: 19px;
		color: $dark-blue;
	}
}

/*FOOTER*/
footer {
	padding: 3em 0 0.2rem;
	background: $black-two;
	.row {
		padding: 0.5rem 0;
	}
	small,
	a {
		color: $white;
		font-size: 12;
	}
	a {
		&:hover {
			color: $sec-blue;
		}
	}
}
.modal-backdrop.fade.show.blueish {
	background-color: #019df4 !important;
	opacity: 1 !important;
}
/*MODAL DE EXITO*/
#successModal {
	.modal-content {
		background-color: transparent !important;
		border: none !important;
		h1 {
			font-size: 80px;
			font-weight: 100;
			color: $white;
		}
		p {
			color: $white;
			font-size: 19px;
			text-align: center;
			font-weight: 100;
			margin: 2rem 0;
		}
	}
	.modal-header-custom,
	.modal-body-custom,
	.modal-footer-custom {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

/*MODAL BIOGRAFICO*/
.biograph {
	.modal-content {
		padding: 2rem;
		.modal-title {
			font-size: 30px;
			font-weight: 300;
			color: $dark-blue;
		}
		.modal-body {
			p {
				font-size: 19px;
				font-weight: 300;
				color: $dark-blue;
			}
		}
	}
	.btn-close {
		border: 0;
		background: transparent;
	}
}

.section-6 form input.input-error {
	border: 1px solid #e66c64;
	color: #e66c64;
	background: #ffebeb;
	margin-bottom: 0.5rem;
}

.error {
	color: #e66c64;
	font-size: 15px;
}

/*Para el tooltip*/
[data-tip]:after {
	display: none;
	content: attr(data-tip);
	position: absolute;
	top: 75px;
	right: -110px;
	padding: 5px 8px;
	background: $main-blue;
	color: #fff;
	z-index: 9;
	font-size: 0.75em;
	line-height: 18px;
	word-wrap: normal;
	width: 240px;
	letter-spacing: 2px;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display: block;
}
