.inv-body {
	.inv-main {
		background: url('./img/invitacion/inv-bkg-desk.jpg') no-repeat / cover;
		margin: auto;
		max-width: 1346px;

		.inv-container {
			margin: auto;
			max-width: 410px;
			min-width: 320px;
			padding: 4rem 0;
			text-align: center;

			.logo {
				width: 359px;
			}

			p {
				color: $white;
				font-size: 20px;

				&.txt-welcome {
					font-size: 24px;
					text-align: left;

					span {

						img {
							width: 260px;
						}
					}
				}

				&.txt-center { text-align: center; }
			}

			.inv-dates {

				h1,
				h3 {
					color: $white;
					font-weight: 100;
				}

				.main-title {
					font-size: 50px;
				}

				.sub-title {
					border-left: 1px solid $white;
					border-right: 1px solid $white;
					font-size: 27px;
					margin: 1.5rem 2rem;
				}
			}

			.inv-qr {
				margin-bottom: 1rem;
				width: 268px;
			}

			.blk-button {
				background: $black;
				color: $white;
				display: block;
				font-size: 18px;
				font-weight: 100;
				margin: auto;
				max-width: 180px;
				padding: 1rem;
			}

			.blk-button-alt {
				background: $white;
				color: $black;
				display: block;
				font-size: 18px;
				font-weight: 100;
				margin: auto;
				max-width: 180px;
				padding: 1rem;
			}
		}
	}

	.inv-cred {
		color: $black !important;
		font-size: 18px;
		font-weight: 100;
		text-align: center;
	}
}
