@font-face {
	font-family: 'MessinaSans';
	src: url('assets/fonts/MessinaSans-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MessinaSans-Bold';
	src: url('assets/fonts/MessinaSans-Bold.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MessinaSans-Light';
	src: url('assets/fonts/MessinaSans-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MessinaSans-Regular';
	src: url('assets/fonts/MessinaSans-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SangBleuEmpire-Regular';
	src: url('assets/fonts/SangBleuEmpire-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SangBleuKingdom-Light';
	src: url('assets/fonts/SangBleuKingdom-Light.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SangBleuKingdom-Regular';
	src: url('assets/fonts/SangBleuKingdom-Regular.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}


