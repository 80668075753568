.big-title {
	font-family: 'Telefonica Sans';
	font-size: 62px;
}
.navbar{
	background: $gray;
}
.front {
	.navbar-nav {
		.nav-link {
			color: $dark-blue;
			font-family: 'Telefonica Sans';
			font-size: 17px;
			padding-left: 1.5rem !important;
			padding-right: 1.5rem !important;
			font-weight: 500;
			letter-spacing: 3px;
			&:hover{
				color: $main-blue;
			}
		}
	}

	.section-1 {
		background: url("./img/index/header.png") no-repeat / cover;
		margin-top: 4rem;

		.hanging-caption {
			background: $white;
			width: 148px;
			padding: 4rem 1rem;
			position: absolute;
			h6{
				font-size: 24px;
				color: $main-blue;
				font-weight: 800;
				&::after{
					content: "";
					height: 5px;
					width: 30px;
					display: block;
					background: $main-blue;
					margin: 1rem 0;
				}
			}
			p{
				color: $main-blue;
				font-size: 15px;
			}
		}

		img {
			margin-top: 16rem;
			width: 674px;
		}
	}

}
