.animated {
	animation-duration: 1s;
	animation-fill-mode: both;

	.animated.infinite {
		animation-iteration-count: infinite;
	}

	.animated.delay-1s {
		animation-delay: 1s;
	}

	.animated.delay-2s {
		animation-delay: 2s;
	}

	.animated.delay-3s {
		animation-delay: 3s;
	}

	.animated.delay-4s {
		animation-delay: 4s;
	}

	.animated.delay-5s {
		animation-delay: 5s;
	}

	.animated.fast {
		animation-duration: 800ms;
	}

	.animated.faster {
		animation-duration: 500ms;
	}

	.animated.slow {
		animation-duration: 2s;
	}

	.animated.slower {
		animation-duration: 3s;
	}
}

@keyframes slide-in-up {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.slide-in-up {
	animation-name: slide-in-up;
}


@keyframes slide-in-left {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.slide-in-left {
	animation-name: slide-in-left;
}

@keyframes fade-in-up {
	from {
		opacity: 0;
		// -webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		// -webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fade-in-up {
	// -webkit-animation-name: fade-in-up;
	animation-name: fade-in-up;
}



// @-webkit-keyframes heart-beat {
// 	0% {
// 		// -webkit-transform: scale(1);
// 		transform: scale(1);
// 	}

// 	14% {
// 		// -webkit-transform: scale(1.3);
// 		transform: scale(1.3);
// 	}

// 	28% {
// 		// -webkit-transform: scale(1);
// 		transform: scale(1);
// 	}

// 	42% {
// 		// -webkit-transform: scale(1.3);
// 		transform: scale(1.3);
// 	}

// 	70% {
// 		// -webkit-transform: scale(1);
// 		transform: scale(1);
// 	}
// }

@keyframes heart-beat {
	0% {
		// -webkit-transform: scale(1);
		transform: scale(1);
	}

	14% {
		// -webkit-transform: scale(1.3);
		transform: scale(1.3);
	}

	28% {
		// -webkit-transform: scale(1);
		transform: scale(1);
	}

	42% {
		// -webkit-transform: scale(1.3);
		transform: scale(1.3);
	}

	70% {
		// -webkit-transform: scale(1);
		transform: scale(1);
	}
}

.heart-beat {
	// -webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	// -webkit-animation-name: heart-beat;
	animation-name: heart-beat;
	// -webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}

@mixin hear-beat() {
	// -webkit-animation-name: heart-beat;
	animation-name: heart-beat;
	// -webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	// -webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}


@mixin transition-bs-service ($time) {
	// -webkit-transition: background-color $time ease-out;
	// -moz-transition: background-color $time ease-out;
	// -o-transition: background-color $time ease-out;
	transition: background-color $time ease-out;
}


@mixin transition-product-opc ($time) {
	// -webkit-animation-delay: $time;
	animation-delay: $time;
}


// @-webkit-keyframes zoom-in {
// 	from {
// 		opacity: 0;
// 		// -webkit-transform: scale3d(0.3, 0.3, 0.3);
// 		transform: scale3d(0.3, 0.3, 0.3);
// 	}

// 	50% {
// 		opacity: 1;
// 	}
// }

@keyframes zoom-in {
	from {
		opacity: 0;
		// -webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

.zoom-in {
	// -webkit-animation-name: zoom-in;
	animation-name: zoom-in;
}


// @-webkit-keyframes swing {
// 	20% {
// 		// -webkit-transform: rotate3d(0, 0, 1, 15deg);
// 		transform: rotate3d(0, 0, 1, 15deg);
// 	}

// 	40% {
// 		// -webkit-transform: rotate3d(0, 0, 1, -10deg);
// 		transform: rotate3d(0, 0, 1, -10deg);
// 	}

// 	60% {
// 		// -webkit-transform: rotate3d(0, 0, 1, 5deg);
// 		transform: rotate3d(0, 0, 1, 5deg);
// 	}

// 	80% {
// 		// -webkit-transform: rotate3d(0, 0, 1, -5deg);
// 		transform: rotate3d(0, 0, 1, -5deg);
// 	}

// 	to {
// 		// -webkit-transform: rotate3d(0, 0, 1, 0deg);
// 		transform: rotate3d(0, 0, 1, 0deg);
// 	}
// }

@keyframes swing {
	20% {
		// -webkit-transform: rotate3d(0, 0, 1, 15deg);
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		// -webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		// -webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		// -webkit-transform: rotate3d(0, 0, 1, -5deg);
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		// -webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

.swing {
	// -webkit-animation-name: swing;
	animation-name: swing;
	// -webkit-transform-origin: top center;
	transform-origin: top center;
}


// @-webkit-keyframes flash {
// 	from,
// 	50%,
// 	to {
// 		opacity: 1;
// 	}

// 	25%,
// 	75% {
// 		opacity: 0;
// 	}
// }

// @keyframes flash {
// 	from,
// 	50%,
// 	to {
// 		opacity: 1;
// 	}

// 	25%,
// 	75% {
// 		opacity: 0;
// 	}
// }

.flash {
	// -webkit-animation-name: flash;
	animation-name: flash;
}

// @-webkit-keyframes tada {
// 	from {
// 		// -webkit-transform: scale3d(1, 1, 1);
// 		transform: scale3d(1, 1, 1);
// 	}

// 	10%,
// 	20% {
// 		// -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
// 		transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
// 	}

// 	30%,
// 	50%,
// 	70%,
// 	90% {
// 		// -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
// 		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
// 	}

// 	40%,
// 	60%,
// 	80% {
// 		// -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
// 		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
// 	}

// 	to {
// 		// -webkit-transform: scale3d(1, 1, 1);
// 		transform: scale3d(1, 1, 1);
// 	}
// }

@keyframes tada {
	from {
		// -webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		// -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
	}

	30%,
	50%,
	70%,
	90% {
		// -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%,
	60%,
	80% {
		// -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	to {
		// -webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.tada {
	// -webkit-animation-name: tada;
	animation-name: tada;
}


// @-webkit-keyframes slide-in-down {
// 	from {
// 		// -webkit-transform: translate3d(0, -100%, 0);
// 		transform: translate3d(0, -100%, 0);
// 		visibility: visible;
// 	}

// 	to {
// 		// -webkit-transform: translate3d(0, 0, 0);
// 		transform: translate3d(0, 0, 0);
// 	}
// }

@keyframes slide-in-down {
	from {
		// -webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		// -webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slide-in-down {
	// -webkit-animation-name: slide-in-down;
	animation-name: slide-in-down;
}



// @-webkit-keyframes zoom-in-left {
// 	from {
// 		// -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
// 		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
// 		opacity: 0;
// 		// -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
// 		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
// 	}

// 	60% {
// 		// -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
// 		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
// 		opacity: 1;
// 		// -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
// 		transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
// 	}
// }

@keyframes zoom-in-left {
	from {
		// -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		opacity: 0;
		// -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
	}

	60% {
		// -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		opacity: 1;
		// -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
	}
}

.zoom-in-left {
	// -webkit-animation-name: zoom-in-left;
	animation-name: zoom-in-left;
}

// @-webkit-keyframes pulse {
// 	from {
// 		// -webkit-transform: scale3d(1, 1, 1);
// 		transform: scale3d(1, 1, 1);
// 	}

// 	50% {
// 		// -webkit-transform: scale3d(1.05, 1.05, 1.05);
// 		transform: scale3d(1.05, 1.05, 1.05);
// 	}

// 	to {
// 		// -webkit-transform: scale3d(1, 1, 1);
// 		transform: scale3d(1, 1, 1);
// 	}
// }

@keyframes pulse {
	from {
		// -webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		// -webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		// -webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.pulse {
	// -webkit-animation-name: pulse;
	animation-name: pulse;
}

@mixin animate-pulse() {
	// -webkit-animation-name: pulse;
	animation-name: pulse;
}
